.social-links {
  position: absolute;
  top: 20px;
  right: max(20px, calc((100vw - var(--vendor-width) * 2) / 2 + 50px));
}

.social-icon {
  height: 48px;
  margin-left: 20px;
  cursor: pointer;
}

.social-icon:hover {
  transform: rotate(15deg);
}
