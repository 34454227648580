.drink-vendor-container {
  display: inline-block;
  position: relative;
  height: 100vh;
  width: min(100vw, var(--vendor-width));
}

/* 1538*2203 */
.drink-vendor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 80%;
  max-width: 90%;
  z-index: 1;
}

.drink-vendor-mint {
  position: absolute;
  right: max(20px, calc((var(--vendor-width)) / 2 + var(--vendor-width) * -0.3));
  top: calc(50vh + var(--vendor-height) * 0.215);
  width: calc(var(--vendor-width) * 0.38);
  cursor: pointer;
  z-index: 2;
}

.drink-vendor-mint:hover {
  animation: shake 0.3s;
  animation-iteration-count: infinite;
}

.drink-vendor-quantity {
  position: absolute;
  right: max(20px, calc((var(--vendor-width)) / 2 + var(--vendor-width) * 0.25));
  top: calc(50vh + var(--vendor-height) * 0.23);
  width: calc(var(--vendor-width) * 0.08);
  cursor: pointer;
  z-index: 2;
}

.drink-vendor-quantity-text {
  position: absolute;
  right: max(20px, calc((var(--vendor-width)) / 2 + var(--vendor-width) * 0.25));
  top: calc(50vh + var(--vendor-height) * 0.23);
  width: calc(var(--vendor-width) * 0.08);
  height: calc(var(--vendor-width) * 0.08 / 114 * 109);
  font-size: min(5vw, 3vh);
  font-weight: bolder;
  text-align: center;
  color: #650601;
  z-index: 3;
}

.drink-vendor-mint-count {
  display: none;
  position: absolute;
  right: max(20px, calc((var(--vendor-width)) / 2 + var(--vendor-width) * -0.3));
  top: calc(50vh + var(--vendor-height) * 0.3);
  width: calc(var(--vendor-width) * 0.4);
  height: calc(var(--vendor-width) * 0.05);
  font-size: min(3vw, 2vh);
  font-weight: bolder;
  text-align: right;
  color: #650601;
  z-index: 1;
}

.drink-vendor-mint-count span {
  color: white;
}

.drink-vendor-price {
  display: none;
  position: absolute;
  right: max(20px, calc((var(--vendor-width)) / 2 + var(--vendor-width) * -0.19));
  top: calc(50vh + var(--vendor-height) * 0.16);
  width: calc(var(--vendor-width) * 0.4);
  height: calc(var(--vendor-width) * 0.05);
  font-size: min(2vw, 1.5vh);
  font-weight: bolder;
  text-align: right;
  color: #650601;
  z-index: 1;
}

.drink-vendor-add {
  position: absolute;
  right: max(20px, calc((var(--vendor-width)) / 2 + var(--vendor-width) * 0.16));
  top: calc(50vh + var(--vendor-height) * 0.23);
  width: calc(var(--vendor-width) * 0.06);
  z-index: 2;
}

.drink-vendor-sub {
  position: absolute;
  right: max(20px, calc((var(--vendor-width)) / 2 + var(--vendor-width) * 0.36));
  top: calc(50vh + var(--vendor-height) * 0.23);
  width: calc(var(--vendor-width) * 0.06);
  z-index: 2;
}

.drink-vendor-add:hover,
.drink-vendor-sub:hover {
  animation: shake 0.3s;
  animation-iteration-count: infinite;
}

.drink-vendor-contract {
  display: none;
  position: absolute;
  right: max(20px, calc((var(--vendor-width)) / 2 + var(--vendor-width) * 0.35));
  top: calc(50vh + var(--vendor-height) * 0.15);
  width: calc(var(--vendor-width) * 0.08);
  height: calc(var(--vendor-width) * 0.08 / 114 * 109);
  z-index: 3;
}

.drink-vendor-contract a {
  font-size: min(3vw, 2vh);
  font-weight: bolder;
  text-align: center;
  color: #650601;
  text-decoration: none;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
