.sun {
  position: absolute;
  top: 0px;
  left: max(-10px, calc((100vw - var(--vendor-width) * 2) * 0.5));
  height: 140px;
  -webkit-animation: rotating 60s linear infinite;
  -moz-animation: rotating 60s linear infinite;
  -ms-animation: rotating 60s linear infinite;
  -o-animation: rotating 60s linear infinite;
  animation: rotating 60s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
