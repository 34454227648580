.vendor-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.vendor-switch-left {
  position: absolute;
  bottom: 10vh;
  left: 8vw;
  width: 40px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.vendor-switch-right {
  position: absolute;
  bottom: 10vh;
  right: 8vw;
  width: 40px;
}
